//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { faCheck } from '@fortawesome/pro-solid-svg-icons';

export default {
  name: 'HomeLicensing',
  props: {
    isCard: {
      type: Boolean,
      default: true,
    },
    showCta: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      faCheck,
      licensesList: [
        'YouTube',
        'Facebook',
        'Instagram',
        'TikTok',
        'Twitter',
        'Twitch',
        'Vimeo',
        this.$t(this.$wbr('term.broadcast')),
        this.$t(this.$wbr('term.commercials')),
        this.$t(this.$wbr('term.tv_&_films')),
        this.$t(this.$wbr('term.online_ads')),
        this.$t(this.$wbr('term.websites')),
        this.$t(this.$wbr('term.podcasts')),
        this.$t(this.$wbr('term.weddings')),
        this.$t(this.$wbr('term.games')),
        this.$t(this.$wbr('term.marketing')),
        this.$t(this.$wbr('term.presentations')),
        this.$t(this.$wbr('term.applications')),
        this.$t(this.$wbr('term.non_profits')),
        this.$t(this.$wbr('term.nfts_&_metaverse_platforms')),
      ],
    };
  },
};
