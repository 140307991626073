//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex';
import { faHandHoldingDollar } from '@fortawesome/pro-light-svg-icons';
import { faMeUnlimitedLight, faMeYoutubeLight } from '@/assets/icons/me-font-icons-regular';
import { publishedContentCounts, renderContentCount } from '@motionelements/core/src/helpers/contentCount.js';
import { getCurrencyByLang } from '@/helpers/currency.js';

const pageNaverTitle = userAgent => /Yeti/.test(userAgent) && '모션엘리먼츠 | 영상소스, 에펙소스, 음악소스, BGM소스';

export default {
  name: 'HomePage',
  components: {
    HomeHero: () => import('@/components/home-page/HomeHero.vue'),
    HomeBenefits: () => import('@/components/home-page/HomeBenefits.vue'),
    HomeFaqs: () => import('@/components/home-page/HomeFaqs.vue'),
    SubscribeBanner: () => import('@/components/home-page/SubscribeBanner.vue'),
    HomeTestimonials: () => import('@/components/home-page/HomeTestimonials.vue'),
    HomePartners: () => import('@/components/home-page/HomePartners.vue'),
    HomeLicensing: () => import('@/components/home-page/HomeLicensing.vue'),
    HomeExplore: () => import('@/components/home-page/HomeExplore.vue'),
    HomeProducts: () => import('@/components/home-page/HomeProducts.vue'),
    HomeSchema: () => import('@/components/home-page/HomeSchema.server.vue'),
    PageMeta: () => import('@motionelements/core/src/components/ThePageMeta.vue'),
  },
  head() {
    return {
      meta: [
        {
          name: 'naver-site-verification',
          content: '1b9cc723a1144d5478a78822645024778a465c91',
        },
      ],
    };
  },
  async asyncData({ req }) {
    // If it's a Naver spider, update the page title
    // Perform user agent detection on the server side
    return { pageNaverTitle: pageNaverTitle(_.get(req, 'headers.user-agent', '')) };
  },
  watch: {
    userCurrency: {
      handler(toCurrencyCode) {
        if (toCurrencyCode) {
          this.$store.dispatch('subscription/getPlans');
        }
      },
      immediate: true,
    },
  },
  async fetch() {
    this.$store.dispatch('user/setCurrencyCode', getCurrencyByLang(this.$i18n.locale));
    return this.$store.dispatch('subscription/getPlans');
  },
  data() {
    return {
      benefits: [
        {
          icon: faMeYoutubeLight,
          color: 'aqua',
          heading: this.$t(this.$wbr('home.benefits.use_for_every_project_heading')),
          text: `${this.$t(this.$wbr('home.benefits.use_for_every_project_subheading')) }<br>${ this.$t(this.$wbr('home.benefits.use_for_every_project_subheading_2'))}`,
        },
        {
          icon: faMeUnlimitedLight,
          color: 'lavender',
          heading: this.$t(this.$wbr('home.benefits.buy_once_use_forever_heading')),
          text: `${this.$t(this.$wbr('home.benefits.buy_once_use_forever_subheading')) }<br>${ this.$t(this.$wbr('home.benefits.buy_once_use_forever_subheading_2'))}`,
        },
        {
          icon: faHandHoldingDollar,
          color: 'pink',
          heading: this.$t(this.$wbr('home.benefits.something_for_every_budget_heading')),
          text: `${this.$t(this.$wbr('home.benefits.something_for_every_budget_subheading'), { amount: '$1' }) }<br>${ this.$t(this.$wbr('home.benefits.something_for_every_budget_subheading_2'))}`,
        },
      ],
    };
  },
  mounted() {
    if (!this.pageNaverTitle) {
      this.pageNaverTitle = pageNaverTitle(_.get(window, 'navigator.userAgent', ''));
    }
  },
  computed: {
    ...mapState({
      languages: state => state.app.languages,
    }),
    ...mapGetters({
      userCurrency: 'user/currencyCode',
      priceMonthly: 'subscription/getPlanPriceMonthlyString',
    }),
    isProduction() {
      return process.env.VUE_APP_ENV === 'production';
    },
    pageTitle() {
      return this.pageNaverTitle || this.$t('home.page_title');
    },
    pageDescription() {
      return this.$t('home.page_description', {
        count: renderContentCount(this, publishedContentCounts.total.subscription.element),
        price: this.priceMonthly,
      });
    },
  },
};
