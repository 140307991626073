//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Swiper, { Pagination } from 'swiper';

Swiper.use([Pagination]);

export default {
  name: 'HomeTestimonials',
  data() {
    return {
      swiper: null,
    };
  },
  mounted() {
    this.initSwiper();
  },
  beforeDestroy() {
    if (this.swiper && this.swiper.el) {
      this.swiper.destroy();
    }
  },
  methods: {
    initSwiper() {
      this.swiper = new Swiper(this.$refs.testimonialsSwiper, {
        slidesPerView: 'auto',
        spaceBetween: 16,
        loop: false,
        autoplay: false,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          clickableClass: 'swiper-pagination-clickable bg-light',
        },
      });
    },
  },
};
