import api from '@motionelements/core/src/api/base-api-v2.js';
import { snakeCaseKeys } from '@motionelements/core/src/helpers/string.js';

export const listChannelFeaturedElements = async (mediaType, params) => {
  mediaType = mediaType.replace('_', '-');
  params = params ? snakeCaseKeys(params) : {};
  return api.get(`/v2/channels/${mediaType}/featured-elements`, {
    withCredentials: false,
    params: {
      ...api.getLocaleParams(),
      ...params,
    },
  });
};


export const listChannelPopularElements = async (mediaType, params) => {
  mediaType = mediaType.replace('_', '-');
  params = params ? snakeCaseKeys(params) : {};
  return api.get(`/v2/channels/${mediaType}/popular-elements`, {
    withCredentials: false,
    params: {
      ...api.getLocaleParams(),
      ...params,
    },
  });
};

export const listChannelCurations = async (mediaType, params) => {
  mediaType = mediaType.replace('_', '-');
  params = params ? snakeCaseKeys(params) : {};
  return api.get(`/v2/channels/${mediaType}/curations`, {
    withCredentials: false,
    params: {
      ...api.getLocaleParams(),
      ...params,
    },
  });
};

export const listChannelCategories = async (mediaType, params) => {
  mediaType = mediaType.replace('_', '-');
  // params = params ? snakeCaseKeys(params) : {};
  params = params || {};
  return api.get(`/v2/channels/${mediaType}/categories`, {
    withCredentials: false,
    params: {
      ...api.getLocaleParams(),
      ...params,
    },
  });
};

export const listMusicalInstruments = async (mediaType) => {
  mediaType = mediaType.replace('_', '-');
  return api.get(`/v2/media-types/${mediaType}/instruments`, {
    withCredentials: false,
    params: {
      ...api.getLocaleParams(),
    },
  });
};

export const listMediaTypeTerms = async mediaType => api.get(`/v2/terms/${mediaType.replace('_', '-')}`, {
  params: api.getLocaleParams(),
});
