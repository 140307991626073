import { render, staticRenderFns } from "./index.vue?vue&type=template&id=47f0b843&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeHero: require('/opt/render/project/src/channels2022/src/components/home-page/HomeHero.vue').default,HomeProducts: require('/opt/render/project/src/channels2022/src/components/home-page/HomeProducts.vue').default,HomeBenefits: require('/opt/render/project/src/channels2022/src/components/home-page/HomeBenefits.vue').default,HomeExplore: require('/opt/render/project/src/channels2022/src/components/home-page/HomeExplore.vue').default,HomeLicensing: require('/opt/render/project/src/channels2022/src/components/home-page/HomeLicensing.vue').default,HomeTestimonials: require('/opt/render/project/src/channels2022/src/components/home-page/HomeTestimonials.vue').default,HomePartners: require('/opt/render/project/src/channels2022/src/components/home-page/HomePartners.vue').default,HomeFaqs: require('/opt/render/project/src/channels2022/src/components/home-page/HomeFaqs.vue').default})
