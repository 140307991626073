//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'HomeHero',
  components: {
    BaseImg: () => import('@motionelements/core/src/components/ui/BaseImg.vue'),
    HeroChannels: () => import('./HeroChannels.vue'),
    HeroSearchBar: () => import('../core/search/HeroSearchBar.vue'),
  },
};
