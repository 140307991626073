// eslint-disable-next-line import/prefer-default-export
export const getCurrencyByLang = (lang) => {
  switch (lang) {
    case 'en':
      return 'USD';
    case 'ja':
      return 'JPY';
    case 'ko':
      return 'KRW';
    case 'th':
      return 'THB';
    case 'zh-hans':
    case 'zh-hant':
      return 'TWD';
    case 'da':
    case 'de':
    case 'es':
    case 'fr':
    case 'hu':
    case 'it':
    case 'nb':
    case 'nl':
    case 'pl':
    case 'pt':
    case 'sv':
    // case 'ru':
    // case 'tr':
      return 'EUR';
    default:
      return 'USD';
  }
};
