//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { publishedContentCounts, renderContentCount } from '@motionelements/core/src/helpers/contentCount.js';

export default {
  name: 'HomeExplore',
  components: {
    BaseImg: () => import('@motionelements/core/src/components/ui/BaseImg.vue'),
  },
  data() {
    return {
      exploreSomethingNewImg: 'https://static.moele.me/img/channels2022/explore-something-new-220815.png?auto=format&w=680',
      totalContentCountText: renderContentCount(this, publishedContentCounts.total.element),
    };
  },
};
