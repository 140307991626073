//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { listChannelFeaturedElements } from '@/api/channels.js';
import channelTypesMixin from '@motionelements/core/src/components/mixins/channelTypes.mixin.js';

export default {
  name: 'HomeProducts',
  mixins: [channelTypesMixin],
  props: {
    channelType: String,
  },
  components: {
    ProductCard: () => import('@motionelements/core/src/components/product-card/ProductCard.vue'),
    ProductCardAudio: () => import('@motionelements/core/src/components/product-card/ProductCardAudio.vue'),
    ProductCardLoader: () => import('@motionelements/core/src/components/product-card/ProductCardLoader.vue'),
  },
  data() {
    return {
      isLoading: false,
      elements: [],
      popularElements: [],
      count: 12,
      perPage: 20,
    };
  },
  mounted() {
    if (this.channelType === 'music') {
      this.count = 5;
      this.perPage = 5;
    }
    this.popularElementsList();
  },
  computed: {
    freeStockUrl() {
      if (this.channelType) {
        return _.get(this.getChannelTypeById(this.channelType), 'freeLink');
      }
      return '/free/stock-footage';
    },
  },
  methods: {
    popularElementsList() {
      this.isLoading = true;
      listChannelFeaturedElements(this.channelType, {
        perPage: this.perPage,
        free: 0,
      })
        .then((response) => {
          this.popularElements = response.data.data;
        })
        .catch((e) => {
          console.warn(e);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
