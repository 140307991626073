//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'HomePartners',
  components: {
    PartnersList: () => import('./PartnersList.vue'),
  },
};
